<template>
  <video-container
    :video-id-prop="videoId" show-only-player
  />
</template>

<script>
  import VideoContainer from '@/entities/video/video.container.vue';

  export default {
    name: 'FullScreenPage',
    components: { VideoContainer },

    data() {
      return {
        videoId: this.$route.params.id,
      };
    },
  }
</script>